<template>
  <div
    class="row m-0 color_dust regular menu_item align-items-center mb-3"
    :class="{ 'active': isActive, 'temp_disabled': menuItem.hidden, 'menu_item_mobile': isMobile}"
     @click.stop="$emit('selectItem', menuItem)"
  >
    <div class="col-10">
      {{menuItem.title}}
    </div>
    <div class="icon" :class="[iconSrc ? 'icon' : 'icon-svg']">
      <img :src="iconSrc" class="img-fluid" v-if="iconSrc">
      <www-icon v-if="menuItem.type && menuItem.type === 'website'" />
      <fb-icon v-if="menuItem.type && menuItem.type === 'facebook'" />
      <ig-icon v-if="menuItem.type && menuItem.type === 'instagram'" />
      <yt-icon v-if="menuItem.type && menuItem.type === 'youtube'" />
      <qr-icon v-if="menuItem.type && menuItem.type === 'qrcode'" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'MenuItem',
  components: {
    fbIcon: () => import('@/icons/Facebook'),
    wwwIcon: () => import('@/icons/Website'),
    igIcon: () => import('@/icons/Instagram'),
    qrIcon: () => import('@/icons/QRCode'),
    ytIcon: () => import('@/icons/YouTube')
  },
  props: {
    isMobile: {
      value: Boolean,
      default: false
    },
    isActive: {
      value: Boolean,
      default: false
    },
    menuItem: {
      value: Object,
      default: null,
      required: true
    }
  },
  computed: {
    iconSrc () {
      if (this.menuItem.icons) {
        return require(`../assets/images/${this.menuItem.icons[this.isActive ? 'active' : 'default']}`)
      }
      return null
    }
  }
}
</script>
<style scoped>

.menu_item {
  /* height: 50px; */
  min-height: 50px;
  cursor: pointer;
  border: 0.75px solid var(--dust);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  font-size: 18px;
}
.icon {
  opacity: 0.7;
}
@media (min-width: 1200px) {
  .menu_item {
    font-size: 20px;
  }
}
.menu_item:hover {
  color: var(--white) !important;
}
.menu_item:hover .icon {
  opacity: 1 !important;
}
.menu_item.active {
  background: var(--beige);
  color: var(--white);
  font-weight: 600;
}
.icon {
  position: absolute;
  right: 1rem;
  height: 2.5rem;
  width: 1.5rem;
}
.icon_website {
  position: absolute;
  right: 2rem;
  height: 2.5rem;
  width: 1.5rem;
}
.icon-svg {
  position: absolute;
  right: 1.5rem;
  height: 2.5rem;
  width: 2.5rem;
}
.temp_disabled {
  cursor: not-allowed;
  color: gray;
}
</style>
